import axios from "axios";
const baseURL = process.env.NEXT_API_CALL;

export const getModules = async (selectedColor) => {
  const response = await axios.get(
    `https://parekitchenbackend.onrender.com/api/modules?populate[options][filters][color][$eq]=${selectedColor}&populate[options][populate][render]=true&populate[options][populate][topView]=true&populate[options][populate][thumbnail]=true&filters[options][color][$eq]=${selectedColor}&sort=order:asc&populate=counter_top`
  );
  return response?.data ?? null;
};

export const getCountertops = async (selectedColor) => {
  const response = await axios.get(
    `https://parekitchenbackend.onrender.com/api/counter-tops?populate=*&filters[options][color][$eq]=${selectedColor}`
  );
  return response?.data ?? null;
};
export const getCountertop = async (counterTopID, selectedColor ) => {
  const response = await axios.get(
    `https://parekitchenbackend.onrender.com/api/counter-tops?populate[options][filters][Color][$eq]=${selectedColor}&populate[options][populate][render]=true&populate[options][populate][topView]=true&populate[options][populate][thumbnail]=true&filters[options][Color][$eq]=${selectedColor}&filters[id][$eq]=${counterTopID}`
  );
  return response?.data ?? null;
};

export const getModule = async (moduleID, selectedColor ) => {
  const response = await axios.get(
    `https://parekitchenbackend.onrender.com/api/modules?populate[options][filters][color][$eq]=${selectedColor}&populate[options][populate][render]=true&populate[options][populate][topView]=true&populate[options][populate][thumbnail]=true&filters[options][color][$eq]=${selectedColor}&populate=counter_top&filters[id][$eq]=${moduleID}`
  );
  return response?.data ?? null;
};



export const getColors = async () => {
  const response = await axios.get(
    `https://parekitchenbackend.onrender.com/api/colors?populate=*&sort=colorOrder:asc`
  );
  return response?.data ?? null;
};


export const getConfiguration = async (dealerCode) => {
  const response = await axios.get(
    `https://parekitchenbackend.onrender.com/api/configurations?populate=*,configItem.module.counter_top.options.render,configItem.module.counter_top.options.topView,configItem.module.options.render.attributes.url,configItem.module.options.topView.attributes.url,configItem.counter_top&filters[configId][$eq]=${dealerCode}`
  );
  return response?.data ?? null;
};

