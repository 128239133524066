import axios from "axios";

const generateDealerCode = async () => {
  const min = 100000;
  const max = 999999;

  let dealerCode;
  let isUnique = false;

  while (!isUnique) {
    dealerCode = Math.floor(Math.random() * (max - min + 1)) + min;

    try {
      const response = await axios.get(
        `https://parekitchenbackend.onrender.com/api/configurations?filters[configId][$eq]=${dealerCode}`
      );
      console.log(response.data.length);
      if (response.data.length === undefined) {
        isUnique = true;
      }
    } catch (error) {
      console.error("Error checking dealer code uniqueness:", error);
      return null;
    }
  }

  return dealerCode.toString();
};

export default generateDealerCode;
