// src/contexts/LanguageContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const defaultLanguageContext = {
  isENG: true, 
  language:"",
  toggleLanguage: () => {} 
};

const LanguageContext = createContext(defaultLanguageContext);

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [isENG, setIsENG] = useState(true);
  const [language, setLanguage]= useState("")

  useEffect(() => {
    if(!localStorage.getItem('language')){
      localStorage.setItem('language', "EN")
    }
    const actualLanguage = localStorage.getItem('language');
    setIsENG(actualLanguage === 'EN');
    setLanguage(localStorage.getItem("language"))
  }, []);

  const toggleLanguage = () => {
    const newLanguage = isENG ? 'DE' : 'EN';
    localStorage.setItem('language', newLanguage)
    setIsENG(!isENG);
    window.location.reload(); 
  };

  return (
    <LanguageContext.Provider value={{language, isENG, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
