// TopViewModal.js
import React from "react";
import "./TopViewModal.css";
import ModalClose from "../../assets/svg/ModalClose.svg";

const TopViewModal = ({ isOpen, closeModal, configuratorElements }) => {
  return (
    <div className={`top-view-modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <img src={ModalClose} onClick={closeModal} id="close" alt="close" />

        <div className="topView">
          {configuratorElements.length > 0 && (
            <div className="heightLineWrapperTop">
              <div className="heightLineNumbers">
                <div className="segWidth2">
                  {configuratorElements[0].moduleDepth}
                </div>
                <p className="segWidth"></p>
                <p className="segWidth">0</p>
              </div>

              <div className="heightLineOrientation">
                <div className="segEnd"></div>
                <div className="heightLine"></div>
                <div className="segStart"></div>
              </div>
            </div>
          )}
          {configuratorElements.map((element, index) => (
            <div key={index} className="topModuliContainer">
              <img
                src={
                  element.module
                    ? `${element.module.options[0].topView.url}`
                    : element.topView
                }
                alt={`Element ${index}`}
                className="topModuli"
              />

              {element.counterTop && (
                <img
                  src={element.counterTop.topView}
                  alt={`Countertop ${index}`}
                  className="topCountertop"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopViewModal;
