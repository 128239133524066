import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const dealerCode = localStorage.getItem("Dealer code");

  if (!dealerCode) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
