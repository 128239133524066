// PartsListModal.js
import React from "react";
import "./PartsList.css";
import ModalClose from "../../assets/svg/ModalClose.svg";
import { useLanguage } from "../../context/Language";

const PartsListModal = ({ isOpen, closeModal, configuratorElements }) => {
  const { isENG } = useLanguage();
  return (
    <div className={`parts-list-modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content-parts">
        <img src={ModalClose} onClick={closeModal} id="close" alt="close" />
        <div className="tableContent">
          <div className="title">
            {isENG ? "Parts list" : "Liste der Einzelteile"}
            <br />
            {isENG
              ? `Current dealer code: ${localStorage.getItem("Dealer code")}`
              : `Aktueller Händlercode: ${localStorage.getItem("Dealer code")}`}
          </div>

          <div className="table-container">
            <table className="partsTable">
              <thead>
                <tr>
                  <th>Position</th>
                  <th>{isENG ? "Article Number" : "Artikelnummer"}</th>
                  <th>{isENG ? "Article" : "Artikel"}</th>
                  <th>{isENG ? "Width" : "Breite"}</th>
                  <th>{isENG ? "Height" : "Höhe"}</th>
                  <th>{isENG ? "Depth" : "Tiefe"}</th>
                </tr>
              </thead>
              <tbody>
                {configuratorElements.map((element, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {element.module
                        ? element?.module?.articleNumber
                        : element.articleNumber}
                    </td>
                    <td>
                      {" "}
                      {isENG
                        ? element.module
                          ? element?.module?.title
                          : element.name
                        : element.module
                        ? element?.module?.titleDE
                        : element.nameDE}
                    </td>
                    <td>
                      {" "}
                      {element.module
                        ? element?.module?.moduleWidth
                        : element.moduleWidth}
                    </td>
                    <td>
                      {" "}
                      {element.module
                        ? element?.module?.moduleHeight
                        : element.moduleHeight}
                    </td>
                    <td>
                      {" "}
                      {element.module
                        ? element?.module?.moduleDepth
                        : element.moduleDepth}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartsListModal;
