import "./App.css";
import { Routes, Route } from "react-router-dom";

import ColorPicker from "./pages/ColorPicker/ColorPicker";
import Configurator from "./pages/Configurator/Configurator";
import ProtectedRoute from "./services/ProtectedRoute";
function App() {
  return (
    <div className="App">
      <Routes>
      <Route path="/" element={<ColorPicker />} />
        <Route
          path="/configurator"
          element={
            <ProtectedRoute>
              <Configurator />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
