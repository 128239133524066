import React, { useEffect, useState } from "react";
import logo from "../../assets/svg/header.svg";
import "./ColorPicker.css";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { getColors } from "../../services/api";
import { useLanguage } from "../../context/Language";
import LoadConfiguration from "../../components/LoadConfiguration/LoadConfiguration";
import generateDealerCode from "../../services/generateDealerCode";

const ColorPicker = () => {
  const [moduleColors, setModuleColors] = useState([]);
  const [counterTopColors, setCounterTopColors] = useState([]);
  const [selectedModuleColor, setSelectedModuleColor] = useState(null);
  const [selectedCounterTopColor, setSelectedCounterTopColor] = useState(null);
  const [warningMessage, setWarningMessage] = useState("");
  const { language, isENG, toggleLanguage } = useLanguage();
  const [isConfigurationModalOpen, setIsConfigurationModalOpen] =
    useState(false);

  const navigate = useNavigate(); // Use useNavigate for programmatic navigation

  const openConfigModal = () => {
    setIsConfigurationModalOpen(true);
  };

  const closeConfigModal = () => {
    setIsConfigurationModalOpen(false);
  };

  const fetchColors = async () => {
    const res = await getColors();

    const moduleColorsArray = res.data.filter(
      (color) => color.type === "Module"
    );
    const topColorsArray = res.data.filter((color) => color.type === "Top");
    setModuleColors(moduleColorsArray);
    setCounterTopColors(topColorsArray);
  };

  const handleModuleColorSelect = (color) => {
    setSelectedModuleColor(color);
    localStorage.setItem("selectedModuleColor", color.slug);
  };

  const handleCounterTopColorSelect = (color) => {
    setSelectedCounterTopColor(color);
    localStorage.setItem("selectedCounterTopColor", color.slug);
  };

  useEffect(() => {
    fetchColors();
  }, []);

  const isButtonDisabled =
    selectedModuleColor === null || selectedCounterTopColor === null;

  const handleGetStartedClick = async (e) => {
    console.log("Get Started button clicked"); // Add log when button is clicked
    localStorage.removeItem("configuratorElements");

    if (selectedModuleColor === null && selectedCounterTopColor === null) {
      e.preventDefault();
      isENG
        ? setWarningMessage(
            "Please select a module color and countertop color."
          )
        : setWarningMessage(
            "Bitte wählen Sie eine Modulfarbe und eine Arbeitsplattenfarbe aus."
          );
      console.log("No module color or countertop color selected");
    } else if (selectedModuleColor === null) {
      e.preventDefault();
      isENG
        ? setWarningMessage("Please select a module color.")
        : setWarningMessage("Bitte wählen Sie eine Modulfarbe aus.");
      console.log("No module color selected");
    } else if (selectedCounterTopColor === null) {
      e.preventDefault();
      isENG
        ? setWarningMessage("Please select a countertop color.")
        : setWarningMessage("Bitte wählen Sie eine Arbeitsplattenfarbe aus.");
      console.log("No countertop color selected");
    } else {
      console.log("Generating dealer code...");
      const dealerCode = await generateDealerCode();
      if (dealerCode) {
        console.log("Generated dealer code:", dealerCode);
        localStorage.setItem("Dealer code", dealerCode);
        console.log("Dealer code saved to localStorage");
        navigate("/configurator"); // Navigate to the configurator page
      } else {
        console.error("Error generating dealer code");
        setWarningMessage("Error generating dealer code");
      }
      setWarningMessage("");
    }
  };

  return (
    <div className="colorPicker">
      <div className="content">
        <img src={logo} className="blacklogo" alt="" />
        <p className="languageSwitch" onClick={toggleLanguage}>
          {language}
        </p>
        <h3>
          {isENG
            ? "Start Custom Configuration"
            : "Starten Sie Ihre Konfiguration"}
        </h3>
      </div>
      <div className="colorWrapper">
        <div className="cabinetsPicker">
          <div className="cabinetText">
            <p className="cabinetTitle">
              {isENG ? "Cabinet Color" : "Farben für Schrank"}
            </p>
          </div>
          <div className="colors">
            {moduleColors.map((color, index) => (
              <div
                className={`color ${
                  selectedModuleColor === color ? "selected" : ""
                }`}
                key={index}
                onClick={() => handleModuleColorSelect(color)}
              >
                <img src={color.image.url} alt="" />
                <p>{isENG ? `${color.colorName}` : `${color.colorNameDE}`}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="countertops">
          <div className="cabinetText">
            <p className="cabinetTitle">
              {isENG ? "Countertop Color" : "Arbeitsplatte"}
            </p>
          </div>
          <div className="colors">
            {counterTopColors.map((color, index) => (
              <div
                className={`color ${
                  selectedCounterTopColor === color ? "selected" : ""
                }`}
                key={index}
                onClick={() => handleCounterTopColorSelect(color)}
              >
                <img src={color.image.url} alt="" />
                <p> {isENG ? `${color.colorName}` : `${color.colorNameDE}`}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="pickerButtons">
        <button
          onClick={handleGetStartedClick}
          className={`getStarted ${isButtonDisabled ? "disabled" : ""}`}
        >
          {isENG ? "get started" : "Jetzt Starten"}
        </button>
        <p className="loadButton" onClick={openConfigModal}>
          {isENG ? "Load configuration" : "Konfiguration Laden"}
        </p>
      </div>

      {warningMessage && <p className="warningMessage">{warningMessage}</p>}

      <LoadConfiguration
        isOpen={isConfigurationModalOpen}
        closeModal={closeConfigModal}
      />
    </div>
  );
};

export default ColorPicker;
