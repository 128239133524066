import React, { useState } from "react";
import "./ConfiguratorModal.css";
import ConfiguratorModalIconRight from "../../assets/svg/ConfiguratorModalIconRight.svg";
import ModalClose from "../../assets/svg/ModalClose.svg";
import { useLanguage } from "../../context/Language";
import { getConfiguration } from "../../services/api";

const ConfiguratorModal = ({
  isOpen,
  closeModal,
  takeScreenshot,
  saveConfigurator,
}) => {
  const [dealerCode, setDealerCode] = useState("");
  const [showDealerCodeModal, setShowDealerCodeModal] = useState(false);
  const [showDealerCodeForm, setShowDealerCodeForm] = useState(false);
  const [configId, setConfigId] = useState("");
  const [error, setError] = useState(false);
  const [successCopy, setSuccessCopy] = useState(false);
  const { isENG } = useLanguage();

  const openDealerCodeModal = () => {
    const generatedDealerCode = generateRandomNumber();
    localStorage.setItem("Dealer code", generatedDealerCode);
    setShowDealerCodeModal(true);
    saveConfigurator(generatedDealerCode);
    setDealerCode(generatedDealerCode);
  };

  const closeDealerCodeModal = () => {
    setShowDealerCodeModal(false);
    setShowDealerCodeForm(false);
    setSuccessCopy(false);
  };

  const openDealerCodeForm = () => {
    setShowDealerCodeForm(true);
  };

  const handleConfigIdChange = (e) => {
    setConfigId(e.target.value);
  };

  const fetchAndSetConfiguration = async () => {
    if (!configId) {
      console.log("No configId provided");
      return;
    }

    try {
      const res = await getConfiguration(configId);
      if (res && res.data.length > 0) {
        localStorage.setItem("selectedModuleColor", res.data[0].moduleColor);
        localStorage.setItem(
          "selectedCounterTopColor",
          res.data[0].counterTopOption
        );
        localStorage.setItem("Dealer code", configId);
        closeModal();
        window.location.reload();
      } else {
        setError(true);
        console.log("Configuration not found");
      }
    } catch (error) {
      console.error("Error fetching configuration:", error);
    }
  };

  const handleConfigIdSubmit = async (e) => {
    e.preventDefault();
    await fetchAndSetConfiguration();
  };

  const copyToClipboard = () => {
    const textarea = document.createElement("textarea");
    textarea.value = dealerCode;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setSuccessCopy(true);
  };

  return (
    <div className={isOpen ? "configuratorModal" : "configuratorModalHidden"}>
      <div className="configuratorModalContent">
        {showDealerCodeModal ? (
          <div className="dealer">
            <img src={ModalClose} onClick={closeDealerCodeModal} alt="close" />
            <div className="dealerCodeContainer">
              <div className="dealerCodeContent">
                <p className="generateDealerCode">
                  {isENG ? "Generate dealer code" : "Händlercode Generieren"}
                </p>
                <div className="dealerCodeText">
                  <span>
                    {isENG
                      ? "The code was created successfully."
                      : "Der Code wurde erfolgreich erstellt."}
                  </span>
                  <br />
                  {isENG
                    ? "Use this code to get more information from your local dealer. If you want to change something in your configuration, you will need to create new code."
                    : "Verwenden Sie diesen Code, um weitere Informationen von Ihrem örtlichen Händler zu erhalten. Wenn Sie etwas an Ihrer Konfiguration ändern möchten, müssen Sie einen neuen Code erstellen."}
                </div>
                <div className="dealerCode">
                  <div
                    className="code"
                    onClick={() => {
                      copyToClipboard();
                    }}
                  >
                    {dealerCode}
                  </div>
                  <div className="copy" onClick={copyToClipboard}>
                    {isENG ? "Copy to Clipboard" : "In Zwischenablage Kopieren"}
                  </div>

                  {successCopy && (
                    <div className="successText">
                      {isENG
                        ? "Dealer code copied to clipboard!"
                        : "Händlercode in Zwischenablage kopiert!"}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : showDealerCodeForm ? (
          <form className="codeForm" onSubmit={handleConfigIdSubmit}>
            <img src={ModalClose} onClick={closeDealerCodeModal} alt="close" />
            <div className="dealerCodeFormContainer">
              <div className="title">
                {isENG
                  ? "Configuration via Dealer code"
                  : "Konfiguration über Händlercode Laden"}
              </div>

              <label htmlFor="inputDealerCode">
                {isENG ? "Dealer code" : "Händlercode"}:
              </label>
              <input
                type="text"
                id="inputDealerCode"
                value={configId}
                onChange={handleConfigIdChange}
                placeholder={isENG ? "Enter here..." : "Geben Sie hier ein..."}
              />
              <button type="submit">
                {isENG ? "Load configuration" : "Konfiguration Laden"}
              </button>
              {error && (
                <p className="configFormErrorMsg">
                  {isENG
                    ? "Dealer code does not exist"
                    : "Händlercode existiert nicht"}
                </p>
              )}
            </div>
          </form>
        ) : (
          <>
            <img src={ModalClose} onClick={closeModal} alt="close" />

            <div className="configuratorModalNavigation">
              <div
                className="configuratorModalNavItem"
                onClick={openDealerCodeModal}
              >
                <p>
                  {isENG ? "Generate dealer code" : "Händlercode Generieren"}
                </p>
                <img src={ConfiguratorModalIconRight} alt="arrow" />
              </div>
              <div
                className="configuratorModalNavItem"
                onClick={saveConfigurator}
              >
                <p>
                  {isENG ? "Save configuration" : "Konfiguration speichern"}
                </p>
                <img src={ConfiguratorModalIconRight} alt="arrow" />
              </div>

              {/* <div
                className="configuratorModalNavItem"
                onClick={takeScreenshot}
              >
                <p>{isENG ? "Save as screenshot" : "Als Screenshot speichern"}</p>
                <img src={ConfiguratorModalIconRight} alt="arrow" />
              </div> */}
              <div
                className="configuratorModalNavItem"
                onClick={openDealerCodeForm}
              >
                <p>
                  {isENG
                    ? "Load configuration via dealer code"
                    : "Konfiguration über Händlercode Laden"}
                </p>
                <img src={ConfiguratorModalIconRight} alt="arrow" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

function generateRandomNumber() {
  const min = 100000;
  const max = 999999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomNumber.toString();
}

export default ConfiguratorModal;
