import React, { useState, useEffect } from "react";
import "./LoadConfiguration.css";
import ModalClose from "../../assets/svg/ModalClose.svg";
import { useLanguage } from "../../context/Language";
import { useNavigate } from "react-router-dom";
import { getConfiguration } from "../../services/api";
const LoadConfiguration = ({ isOpen, closeModal }) => {
  const [configId, setConfigId] = useState("");
  const [error, setError] = useState(false);
  const { isENG } = useLanguage();
  const navigate = useNavigate();

  const fetchConfiguration = async () => {
    try {
      const configId = localStorage.getItem("Dealer code");
      const res = await getConfiguration(configId);

      localStorage.setItem("selectedModuleColor", res.data[0].moduleColor);
      localStorage.setItem(
        "selectedCounterTopColor",
        res.data[0].counterTopOption
      );
    } catch (error) {
      console.error(error);
    }
  };
  // const handleDealerCodeSubmit = async (e) => {
  //   e.preventDefault();
  //   localStorage.setItem("Dealer code", inputDealerCode);
  //   fetchConfiguration();
  //   closeModal();
  //   navigate("/configurator");
  // };
  const handleConfigIdChange = (e) => {
    setConfigId(e.target.value);
  };

  const fetchAndSetConfiguration = async () => {
    if (!configId) {
      console.log("No configId provided");
      return;
    }

    try {
      const res = await getConfiguration(configId);
      if (res && res.data.length > 0) {
        localStorage.setItem("selectedModuleColor", res.data[0].moduleColor);
        localStorage.setItem(
          "selectedCounterTopColor",
          res.data[0].counterTopOption
        );
        localStorage.setItem("Dealer code", configId);
        fetchConfiguration();
        closeModal();
        navigate("/configurator");
      } else {
        setError(true);
        console.log("Configuration not found");
      }
    } catch (error) {
      console.error("Error fetching configuration:", error);
    }
  };

  const handleConfigIdSubmit = async (e) => {
    e.preventDefault();
    await fetchAndSetConfiguration();
  };

  return (
    <div className={isOpen ? "configuratorModal" : "configuratorModalHidden"}>
      <div className="configuratorModalContent">
        <form className="codeForm" onSubmit={handleConfigIdSubmit}>
          <img src={ModalClose} onClick={closeModal} alt="close" />
          <div className="dealerCodeFormContainer">
            <div className="title">
              {isENG
                ? "Configuration via Dealer code"
                : "Konfiguration über Händlercode Laden"}
            </div>

            <label htmlFor="inputDealerCode">
              {isENG ? "Dealer code" : "Händlercode"}:
            </label>

            <input
              type="text"
              id="inputDealerCode"
              value={configId}
              onChange={handleConfigIdChange}
              placeholder={isENG ? "Enter here..." : "Geben Sie hier ein..."}
            />
            <button type="submit">
              {isENG ? "Load configuration" : "Konfiguration Laden"}
            </button>
            {error && (
              <p className="configFormErrorMsg">
                {isENG
                  ? "Dealer code does not exist"
                  : "Händlercode existiert nicht"}
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoadConfiguration;
