import React, { useState } from "react";
import "./SumbitForm.css";
import ModalClose from "../../assets/svg/ModalClose.svg";
import axios from "axios";
import { useLanguage } from "../../context/Language";

const SumbitForm = ({ isOpen, closeModal, onSubmitSuccess, screenshot }) => {
  const [showFormSuccessModal, setShowFormSuccessModal] = useState(false);
  const { isENG } = useLanguage();
  const [modifiedData, setModifiedData] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    street: "",
    postalCode: "",
    city: "",
    country: "",
    agreement: false,
    status: "Pending",
  });
  const closeFormSuccessModal = () => {
    setShowFormSuccessModal(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setModifiedData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
      configId: localStorage.getItem("Dealer code"),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if a configId exists in localStorage, if not generate a new one
    let configId = localStorage.getItem("Dealer code");
    if (!configId) {
      // Generate a random 6-digit number as the configId
      configId = Math.floor(100000 + Math.random() * 900000).toString();
      localStorage.setItem("Dealer code", configId);
    }
  
    const submissionData = {
      ...modifiedData,
      configId, // Use the configId from localStorage or the newly generated one
      screenshot: localStorage.getItem("screenshot"), // Assuming `screenshot` is the base64 string
    };
  
    try {
      const response = await axios.post(
        "https://parekitchenbackend.onrender.com/api/sumbit-forms",
        {
          data: submissionData,
        }
      );
      const responseAll = await axios.post(
        "https://parekitchenbackend.onrender.com/api/sendForm",
        {
          data: submissionData,
        }
      );
  
      if (response.status === 200 && responseAll.status === 200) {
        if (onSubmitSuccess && typeof onSubmitSuccess === "function") {
          onSubmitSuccess();
        }
        setShowFormSuccessModal(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <>
      <div className={`contact-form-modal ${isOpen ? "open" : ""}`}>
        <div className="modal-content">
          <img src={ModalClose} onClick={closeModal} id="close" alt="close" />
          <section>
            <div className="contact-form-header">
              <div className="bigTitle">
                {isENG ? "Send Inquiry" : "Anfrage unverbindlich senden"}
              </div>
              <div className="contact-from-description">
                {isENG
                  ? "Please enter your details to send your configuration to us send. We will then contact you."
                  : "Bitte geben Sie Ihre Daten ein, um Ihre Konfiguration an uns zu senden. Wir werden uns dann mit Ihnen in Verbindung setzen."}
              </div>
            </div>
            <form className="form-container" onSubmit={handleSubmit}>
              <input
                className="form-input"
                type="text"
                id="name"
                name="name"
                placeholder={isENG ? "Name" : "Vorname"}
                value={modifiedData.name}
                onChange={handleChange}
                required
              />
              <input
                className="form-input"
                type="text"
                id="surname"
                name="surname"
                placeholder={isENG ? "Surname" : "Nachname"}
                value={modifiedData.surname}
                onChange={handleChange}
                required
              />

              <input
                className="form-input"
                type="email"
                id="email"
                name="email"
                placeholder="E-Mail"
                value={modifiedData.email}
                onChange={handleChange}
                required
              />
              <input
                className="form-input"
                type="text"
                id="phone"
                name="phone"
                placeholder={isENG ? "Phone" : "Telefon"}
                value={modifiedData.phone}
                onChange={handleChange}
                required
              />
              <input
                className="form-input"
                type="text"
                id="street"
                name="street"
                placeholder={isENG ? "Street" : "Straße"}
                value={modifiedData.street}
                onChange={handleChange}
                required
              />
              <input
                className="form-input"
                type="text"
                id="postalCode"
                name="postalCode"
                placeholder={isENG ? "Postal Code" : "Postleitzahl"}
                value={modifiedData.postalCode}
                onChange={handleChange}
                required
              />
              <input
                className="form-input"
                type="text"
                id="city"
                name="city"
                placeholder={isENG ? "City" : "Stadt"}
                value={modifiedData.city}
                onChange={handleChange}
                required
              />
              <input
                className="form-input"
                type="text"
                id="country"
                name="country"
                placeholder={isENG ? "Country" : "Land"}
                value={modifiedData.country}
                onChange={handleChange}
                required
              />
              {/* <input
              className="form-input"
              type="hidden"
              id="configId"
              name="configId"
              placeholder="configId"
              value={modifiedData.configId}
              onChange={handleChange}
              required
            /> */}

              {/* Add your submit button */}
              <div className="contact-form-bottom">
                <button type="submit" className="sendForm">
                  {isENG ? "Send Inquiry" : "Jetzt Anfragen"}
                </button>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    name="agreement"
                    id="agreement"
                    checked={modifiedData.agreement}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="agreement">
                    {isENG
                      ? "I agree that my data will be stored for the purpose of the configuration request and passed on to our dealers."
                      : "Ich bin damit einverstanden, dass meine Daten zum Zwecke der Konfigurationsanfrage gespeichert und an unsere Händler weitergegeben werden."}
                  </label>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
      {showFormSuccessModal && (
        <div className="succesModal">
          <div className="successWrapper">
            <img src={ModalClose} onClick={closeFormSuccessModal} alt="close" />
            <div className="successContainer">
              <div className="successContent">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  fill="none"
                >
                  <path
                    d="M55 27.7V30C54.9969 35.3911 53.2513 40.6367 50.0233 44.9546C46.7954 49.2725 42.2582 52.4312 37.0884 53.9598C31.9186 55.4883 26.3931 55.3048 21.3362 53.4365C16.2792 51.5682 11.9616 48.1153 9.0274 43.5927C6.09317 39.0701 4.69948 33.7202 5.05419 28.3408C5.40891 22.9614 7.49302 17.8408 10.9957 13.7427C14.4984 9.64458 19.232 6.78848 24.4905 5.60039C29.749 4.4123 35.2507 4.95587 40.175 7.15003"
                    stroke="#F49800"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M55 10L30 35.025L22.5 27.525"
                    stroke="#F49800"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="successText">
                  {isENG ? "Submitted successfully" : "Erfolgreich gesendet"}
                </p>
                <div className="successDescription">
                  {isENG
                    ? "Your request has been sent successfully, please wait for our review."
                    : "Ihre Anfrage wurde erfolgreich versendet, bitte warten Sie auf unsere Bewertung."}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SumbitForm;
